.payment-loader {
    margin: auto;
    border: 13px solid #f3f3f3;
    border-radius: 50%;
    border-top: 13px solid #8fb131;
    border-right: 13px solid #8fb131;
    border-bottom: 13px solid #8fb131;
    width: 74px;
    height: 74px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
